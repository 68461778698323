import {
  CHANGE_GLOBAL_DISPLAY_GROUP,
  CHANGE_CURRENT_FEATURE,
  CHNAGE_PRIMARY_SIDEBAR_DISPLAY,
  CHANGE_MOBILE_VIEW,
  SET_ORG_LIST,
  SET_SELECTED_ORG,
  SET_GROUPS,
  CHANGE_FEATURES_RESPONSE,
} from './actionTypes';

const INIT_STATE = {
  globalDisplayGroup: {},
  currentFeature: '',
  primarySidebarShow: true,
  mobileView: window.innerWidth < 800,
  orgList: [],
  selectedOrg: null,
  groups: [],
  featuresResponse: {},
};

const Global = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_FEATURES_RESPONSE:
      return {
        ...state,
        featuresResponse: action.payload,
      };
    case CHANGE_GLOBAL_DISPLAY_GROUP:
      return {
        ...state,
        globalDisplayGroup: {
          ...state.globalDisplayGroup,
          ...action.payload,
        },
      };
    case CHANGE_CURRENT_FEATURE:
      return {
        ...state,
        currentFeature: action.payload,
      };
    case CHNAGE_PRIMARY_SIDEBAR_DISPLAY:
      return {
        ...state,
        primarySidebarShow: action.payload,
      };
    case CHANGE_MOBILE_VIEW:
      return {
        ...state,
        mobileView: action.payload,
      };
    case SET_ORG_LIST:
      return {
        ...state,
        orgList: action.payload,
      };
    case SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrg: action.payload,
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };

    default:
      return state;
  }
};

export default Global;
