import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState, useCallback } from 'react';

const FRESHCHAT_API_BASE = process.env.REACT_APP_AUTH0_URL;
const FRESHCHAT_TOKEN = process.env.REACT_APP_FRESHCHAT_TOKEN;
const FRESHCHAT_HOST =
  process.env.REACT_APP_FRESHCHAT_HOST || 'https://wchat.freshchat.com';

const FreshchatWidget = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isInitialized, setIsInitialized] = useState(false);

  // Get stored restore ID from our backend
  const getStoredRestoreId = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${FRESHCHAT_API_BASE}/fresh_chat_sdk/session/${user?.sub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data?.session?.restore_id || null;
    } catch (error) {
      console.error('Failed to get restore ID:', error);
      return null;
    }
  }, [user?.sub, getAccessTokenSilently]);

  // Store restore ID in our backend
  const storeRestoreId = useCallback(
    async (restoreId) => {
      try {
        const token = await getAccessTokenSilently();
        await fetch(`${FRESHCHAT_API_BASE}/fresh_chat_sdk/session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: user?.sub,
            restore_id: restoreId,
          }),
        });
      } catch (error) {
        console.error('Failed to store restore ID:', error);
      }
    },
    [user?.sub, getAccessTokenSilently]
  );

  // const removeRestoreId = useCallback(async () => {
  //   try {
  //     const token = await getAccessTokenSilently();
  //     await fetch(`${FRESHCHAT_API_BASE}/fresh_chat_sdk/remove_session`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         user_id: user?.sub,
  //       }),
  //     });
  //   } catch (error) {
  //     console.error('Failed to remove restore ID:', error);
  //   }
  // }, [user?.sub, getAccessTokenSilently]);

  const initializeFreshchat = useCallback(async () => {
    try {
      const existingRestoreId = await getStoredRestoreId();

      // Initialize Freshchat widget
      window.fcSettings = {
        token: FRESHCHAT_TOKEN,
        host: FRESHCHAT_HOST,
        externalId: user.sub,
        restoreId: existingRestoreId,
        firstName: user.nickname || user.email,
        email: user.email,
      };

      const script = document.createElement('script');
      script.async = true;
      script.src = `${FRESHCHAT_HOST}/js/widget.js`;
      script.onload = () => {
        window.fcWidget.on('user:created', function (resp) {
          if (resp?.data?.restoreId) {
            storeRestoreId(resp.data.restoreId);
          }
        });

        // window.fcWidget.on('csat:received', async function (data) {
        //   await removeRestoreId();
        // });

        window.fcWidget.on('widget:initialized', function () {
          setIsInitialized(true);
        });

        window.fcWidget.user.get(function (resp) {
          if (resp?.data?.restoreId && !existingRestoreId) {
            storeRestoreId(resp.data.restoreId);
          }
        });

        window.fcWidget.on('conversation:status-changed', function (resp) {
          if (resp?.data?.status === 'RESOLVED') {
            console.log('Conversation resolved.');
          }
        });
      };

      const existingScript = document.getElementById('freshchat-js-sdk');
      if (existingScript) {
        existingScript.remove();
      }

      document.head.appendChild(script);
    } catch (error) {
      console.error('Freshchat initialization error:', error);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    initializeFreshchat();

    return () => {
      if (window.fcWidget) {
        window.fcWidget.destroy();
        setIsInitialized(false);
      }
    };
  }, [user, initializeFreshchat]);

  return null;
};

export default FreshchatWidget;
