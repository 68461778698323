import React from 'react';
import initSentry from './config/sanitary';
// import ReactGA from 'react-ga';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
// import ReactGA from "react-ga4";
import { Provider } from 'react-redux';
// import "./i18n";
// import amplitude from 'amplitude-js';
import { configureStore } from './store/store';

// import { Auth0Provider } from '@auth0/auth0-react';
// import { useAuth0 } from '@auth0/auth0-react';
// import history from './helpers/Auth0/history';
import { createBrowserHistory } from 'history';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory();

// ReactGA.initialize("G-9XZM9DD6XG");

// const amplitudeInstance = amplitude.getInstance();
// amplitudeInstance.init("f2296121e8511fb05dac6bec189457a");

// amplitudeInstance.logEvent('LOGGED IN', { 'page_name': 'root' });

root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter history={history}>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: "window.location.pathname",
//   });
// }
// reportWebVitals(SendAnalytics);

// serviceWorker.unregister();
